



import { defineComponent, onMounted } from '@vue/composition-api'
import { urlPath } from 'utils'
import { Auth } from 'aws-amplify'

const LoginCallBack = defineComponent({
  setup: (props, { root }) => {
    onMounted(async () => {
      try {
        const user = await Auth.currentAuthenticatedUser()
        const groups = user.signInUserSession.accessToken.payload['cognito:groups']
        if (groups.includes(process.env.VUE_APP_COGNITO_USER_GROUP)) {
          root.$router.replace({
            name: urlPath.START.name
          })
        }
      } catch (err) {
        Auth.signOut().then(() => {
          // redirect to HostedUI page
          Auth.federatedSignIn()
        })
      }
    })
  }
})

export default LoginCallBack
